import fetcher from "./fetcher";

const localStorageName = 'ONVS';

const createSecureToken = async () => {
    try {
        const { data } = await fetcher(`/api/sessions`, {
            body: JSON.stringify({}),
            headers: { "Content-Type": "application/json" },
            method: "POST",
        })
        localStorage.setItem(localStorageName, JSON.stringify(data));
        return;
    } catch (e) {
        console.error(e);
        return;
    }
}

const getSecureToken = async () => {
    const { id } = JSON.parse(localStorage.getItem(localStorageName) || '');
    if (!id) return null;
    try {
        const { data } = await fetcher(`/api/sessions?id=${id}`, {
            headers: { "Content-Type": "application/json" },
            method: "GET",
        })
        localStorage.setItem(localStorageName, JSON.stringify(data));
        return data?.token;
    } catch (e) {
        console.error(e);
        return null;
    }
}

const cleanSecureToken = () => {
    localStorage.removeItem(localStorageName);
}

export { createSecureToken, getSecureToken, cleanSecureToken };
