/**
 * Make object from another, swaping the keys and the values
 */
export const revertObject = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key]
    acc[value] = key
    return acc
  }, {})
}

/**
 * Rename properties of an object with a mapping object.
 *
 * @param {*} obj the original object to transform
 * @param {*} mapping object with key is the property to be found in the object and value the new property to add to the object
 */
export const renameKeys = (obj, mapping) =>
  Object.fromEntries(
    Object.keys(mapping)
      .map((oldKey) => [mapping[oldKey], obj[oldKey]])
      .filter((curr) => !!curr[1]),
  )

export const isEmpty = (object) => !Object.keys(object).length

export function flatObject(object, keys) {
  const res = keys.reduce((acc, key) => {
    const props = object[key]
    return {
      ...acc,
      ...props,
    }
  }, {})

  return res
}

export const objToArray = (obj, labels = []) => {
  if (!obj) return []

  if (!labels?.length)
    return Object.keys(obj).map((property) => ({
      name: property,
      value: obj[property] || 0,
    }))

  return labels
    .map((label) => {
      switch (typeof label) {
        case "string":
          return {
            name: label,
            value: obj[label],
          }
        case "object": {
          // Assert there is only one key in label object
          const [key] = Object.keys(label)

          return {
            name: label[key],
            value: obj[key],
          }
        }
        default:
          return { value: undefined }
      }
    })
    .filter((item) => !!item.value)
}
